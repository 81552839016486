(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/ticket-count.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/ticket-count.js');
"use strict";


const {
  Grid,
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const Icon = svs.ui.ReactIcon;
const IconButton = svs.ui.ReactIconButton;
const Button = svs.ui.ReactButton;
const PRICE_PACKAGES = [1, 4, 8];
const INCREMENT_DISABLED_CLASS = 'fc-opacity-20';
const TicketCount = _ref => {
  let {
    count,
    handleOnChangeCount,
    price
  } = _ref;
  const MIN_TICKETS = count === 0 ? 0 : 1;
  const MAX_TICKETS = count === 0 ? 0 : 1000;
  const handleCountClick = e => {
    var _e$currentTarget$data, _e$currentTarget, _e$currentTarget$data2, _e$currentTarget2;
    svs.supportern.logger.debug('COUNT CLICKED');
    e.preventDefault();
    let {
      increment
    } = (_e$currentTarget$data = (_e$currentTarget = e.currentTarget) === null || _e$currentTarget === void 0 ? void 0 : _e$currentTarget.dataset) !== null && _e$currentTarget$data !== void 0 ? _e$currentTarget$data : {};
    const {
      set
    } = (_e$currentTarget$data2 = (_e$currentTarget2 = e.currentTarget) === null || _e$currentTarget2 === void 0 ? void 0 : _e$currentTarget2.dataset) !== null && _e$currentTarget$data2 !== void 0 ? _e$currentTarget$data2 : {};
    let result;
    if (increment) {
      increment = parseInt(increment, 10);
      result = count + increment;
    }
    if (set) {
      result = parseInt(set, 10);
    }
    if (result >= MIN_TICKETS && result <= MAX_TICKETS) {
      handleOnChangeCount(result);
    }
  };
  return React.createElement(Grid, null, count !== 0 && React.createElement(GridRow, {
    className: "supportern-price-packages margin-bottom-2"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-center gap-1",
    xs: 12
  }, PRICE_PACKAGES.map(packageCount => React.createElement(Button, {
    block: true,
    "data-set": packageCount,
    inverted: count === packageCount,
    key: packageCount,
    onClick: handleCountClick,
    transparent: count !== packageCount
  }, packageCount, " st: ", price * packageCount, " kr")))), React.createElement(GridRow, {
    className: "padding-left-1 padding-right-1"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-center",
    xs: 5
  }, React.createElement(IconButton, {
    "aria-label": "minska",
    "data-increment": -1,
    href: "#",
    onClick: handleCountClick
  }, React.createElement(Icon, {
    color: count <= MIN_TICKETS ? INCREMENT_DISABLED_CLASS : '',
    icon: "minus",
    size: "500"
  }))), React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center u-flex-align-items-center",
    xs: 2
  }, React.createElement("span", {
    className: "f-800"
  }, "".concat(count).padStart(2, '0'))), React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center f-center",
    xs: 5
  }, React.createElement(IconButton, {
    "aria-label": "\xF6ka",
    "data-increment": 1,
    onClick: handleCountClick
  }, React.createElement(Icon, {
    color: count >= MAX_TICKETS ? INCREMENT_DISABLED_CLASS : '',
    icon: "plus",
    size: "500"
  })))));
};
setGlobal('svs.supportern.components.TicketCount', TicketCount);

 })(window);