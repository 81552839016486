(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/tracking.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/tracking.js');
"use strict";

const {
  TRACKING_DRAW_TYPES
} = svs.supportern.utils.tools;
setGlobal('svs.supportern.utils.tracking', {
  onBuy: (orderId, drawId, quantity, drawPrice, fund, drawType, teamName) => {
    const transaction = new svs.components.analytics.transaction(orderId, svs.utils.format.Currency(drawPrice), [new svs.components.analytics.transactionItem(drawId, 'Supportern', svs.utils.format.Currency(drawPrice / quantity), quantity, {
      fund,
      supporternEvent: TRACKING_DRAW_TYPES[drawType],
      supporternTeam: teamName
    })], 0, 0, undefined, 'SUPPORTERN');
    svs.components.analytics.trackTransaction(transaction);
  },
  onFavoriteTeam: team => {
    const track = {
      category: 'Supportern',
      action: 'favorite',
      opt_label: team
    };
    svs.components.analytics.trackEvent(track);
  },
  onReadMoreOngoingClick: () => {
    const track = {
      category: 'Supportern',
      action: 'ongoing_games',
      opt_label: 'read_more'
    };
    svs.components.analytics.trackEvent(track);
  },
  onReadMoreFinishedClick: () => {
    const track = {
      category: 'Supportern',
      action: 'ended_games',
      opt_label: 'read_more'
    };
    svs.components.analytics.trackEvent(track);
  },
  onBuyMoreClick: () => {
    const track = {
      category: 'Supportern',
      action: 'choose_game',
      opt_label: 'buy_more_game'
    };
    svs.components.analytics.trackEvent(track);
  },
  onTabLeague: league => {
    const track = {
      category: 'Supportern',
      action: 'tab_click_league',
      opt_label: league
    };
    svs.components.analytics.trackEvent(track);
  },
  onChooseGame: team => {
    const track = {
      category: 'Supportern',
      action: 'choose_game',
      opt_label: team
    };
    svs.components.analytics.trackEvent(track);
  },
  onExtendEndedGame: () => {
    const track = {
      category: 'Supportern',
      action: 'ended_games',
      opt_label: 'click'
    };
    svs.components.analytics.trackEvent(track);
  },
  onExtendOngoingGame: () => {
    const track = {
      category: 'Supportern',
      action: 'ongoing_games',
      opt_label: 'click'
    };
    svs.components.analytics.trackEvent(track);
  },
  onResultPresented: (winAmount, sport) => {
    const track = {
      category: 'Supportern',
      action: 'correction-complete-qualtrics',
      opt_label: sport,
      opt_value: winAmount
    };
    svs.components.analytics.trackEvent(track);
  },
  onOverlayOK: () => {
    const track = {
      category: 'overlays',
      action: 'ok',
      opt_label: 'Supportern'
    };
    svs.components.analytics.trackEvent(track);
  },
  onOverlayNavigate: () => {
    const track = {
      category: 'overlays',
      action: 'mina_supporternspel',
      opt_label: 'Supportern'
    };
    svs.components.analytics.trackEvent(track);
  }
});

 })(window);