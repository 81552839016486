(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/anchor-behavior.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/anchor-behavior.js');
"use strict";

const {
  useEffect
} = React;
const paramHandler = svs.utils.paramHandler;
setGlobal('svs.supportern.utils.AnchorBehaviour', () => {
  useEffect(() => {
    let currentHash = window.location.hash;
    if (!currentHash) {
      currentHash = decodeURIComponent(paramHandler.get({
        key: 'anchor'
      }).value);
    }
    if (currentHash) {
      setTimeout(() => {
        const anchorElement = document.querySelector(currentHash);
        if (anchorElement) {
          anchorElement.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 1000);
    }
  }, []);
  return null;
});

 })(window);