(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/atoms/draw-atoms.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/atoms/draw-atoms.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const {
  apiController
} = svs.supportern.utils;
const {
  onBuy
} = svs.supportern.utils.tracking;
const {
  DRAWS
} = svs.supportern.utils.rafflePaths;
const {
  DRAW_STATS,
  BUY_TICKETS
} = svs.supportern.utils.poolPaths;
const {
  atom
} = jotai;
const {
  atomsWithQuery,
  queryClientAtom,
  atomsWithMutation
} = jotaiTanstackQuery;
const drawAtoms = {
  drawIdAtom: atom(),
  drawAtom: atom(),
  drawsAtom: atom(),
  drawsFilterAtom: atom(),
  drawsByFilterAtom: atomsWithQuery(get => ({
    queryKey: ['draws', get(drawAtoms.drawsFilterAtom)],
    queryFn: async _ref => {
      let {
        queryKey
      } = _ref;
      const queryClient = get(queryClientAtom);
      const data = queryClient.getQueryData(queryKey);
      if (data) {
        if (!data.length) {
          return data;
        }
        const drawIDs = data.map(draw => draw.id);
        try {
          const updatedStats = await apiController({
            path: DRAW_STATS,
            query: "drawId=".concat(drawIDs.join('&drawId=')),
            suppressErrors: true
          });
          if (drawIDs.length === updatedStats.length) {
            return data.map(draw => {
              var _updatedStats$find;
              return _objectSpread(_objectSpread({}, draw), {}, {
                results: _objectSpread(_objectSpread({}, draw.results), {}, {
                  stats: (_updatedStats$find = updatedStats.find(stats => stats.id === draw.id)) === null || _updatedStats$find === void 0 ? void 0 : _updatedStats$find.stats
                })
              });
            });
          }
        } catch (e) {
          svs.supportern.logger.warn('Failed to update draws', e);
          return data;
        }
      }
      const drawsFilter = get(drawAtoms.drawsFilterAtom);
      if (!drawsFilter) {
        svs.supportern.logger.warn('drawsByTeamIdsAtom requires drawsFilter Provider');
        return null;
      }
      try {
        const {
          items,
          serverTime
        } = await apiController({
          path: DRAWS,
          query: "pageSize=50&".concat(drawsFilter)
        });
        return items.map(item => _objectSpread(_objectSpread({}, item), {}, {
          serverTime
        }));
      } catch (e) {
        return [];
      }
    },
    refetchInterval: data => {
      if (Array.isArray(data) && data.length === 0) {
        return 0;
      }
      return 5000;
    }
  }))[0],
  paymentMutationAtoms: atomsWithMutation(get => {
    const onError = (_error, _ref2) => {
      let {
        draw = {},
        cancel
      } = _ref2;
      const {
        id,
        results
      } = draw;
      svs.supportern.logger.warn("Payment failed on drawId: ".concat(id));
      if (results) {
        var _get;
        (_get = get(queryClientAtom)) === null || _get === void 0 || _get.setQueriesData(['draws'], draws => svs.supportern.utils.payment.updateDraws({
          draws,
          id,
          results
        }));
      }
      cancel();
    };
    return {
      mutationKey: ['draws'],
      mutationFn: async _ref3 => {
        let {
          draw,
          count,
          cancel
        } = _ref3;
        svs.utils.paramHandler.remove({
          key: 'afterpay'
        });
        svs.utils.paramHandler.remove({
          key: 'pay'
        });
        try {
          var _stats$poolSize, _get2;
          const data = await apiController({
            path: BUY_TICKETS,
            options: {
              shouldReturnData: true,
              method: 'POST',
              body: JSON.stringify({
                tenantId: 'bwloto',
                channel: svs.core.data.capabilities.channel,
                gameId: 'raffleGame',
                drawId: draw.id,
                numberOfTickets: count
              })
            }
          });
          const {
            id,
            results
          } = draw;
          const {
            stats
          } = results;
          const newTotalRows = data.drawInfo.stats.totalRows;
          const newPoolSizeAmount = data.drawInfo.stats.poolSize.amount;
          const newResults = _objectSpread(_objectSpread({}, results), {}, {
            stats: _objectSpread(_objectSpread({}, stats), {}, {
              totalRows: newTotalRows,
              poolSize: _objectSpread(_objectSpread({}, (_stats$poolSize = stats.poolSize) !== null && _stats$poolSize !== void 0 ? _stats$poolSize : {}), {}, {
                amount: newPoolSizeAmount
              })
            })
          });
          (_get2 = get(queryClientAtom)) === null || _get2 === void 0 || _get2.setQueriesData(['draws'], draws => svs.supportern.utils.payment.updateDraws({
            draws,
            id,
            results: newResults
          }));
          return _objectSpread(_objectSpread({}, data), {}, {
            count
          });
        } catch (e) {
          if (e.response && !e.response.ok) {
            var _e$response$parsed;
            if (((_e$response$parsed = e.response.parsed) === null || _e$response$parsed === void 0 ? void 0 : _e$response$parsed.code) === '5 139') {
              return;
            }
            svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
              branding: svs.ui.dialog.branding.TUR,
              type: svs.ui.dialog.type.ERROR,
              title: 'Något gick fel',
              message: [{
                type: svs.ui.dialog.message.TEXT,
                text: 'Ditt köp kunde inte genomföras, inga pengar har dragits.'
              }],
              actions: [{
                title: 'OK'
              }]
            }));
          }
          onError('', {
            draw,
            cancel
          }, {});
          return {
            error: 'payment failed'
          };
        }
      },
      onError,
      onSuccess: (payload, options) => {
        const {
          next,
          cancel,
          draw
        } = options;
        if (payload !== null && payload !== void 0 && payload.ticketInfo) {
          const {
            gameId,
            id,
            ticketNumbersRange,
            buy
          } = payload.ticketInfo;
          const count = ticketNumbersRange.to - ticketNumbersRange.from + 1;
          const fund = (payload === null || payload === void 0 ? void 0 : payload.drawInfo.stats.poolSize.amount) || 0;
          onBuy(id, gameId, count, buy.price.amount, Number(fund), draw.type, draw.ownerTeam.name);
          svs.supportern.logger.info('Payment successful');
          next();
        } else {
          cancel();
        }
      }
    };
  })[1]
};
setGlobal('svs.supportern.atoms', drawAtoms);

 })(window);